import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
@Injectable()
export class InformationsService {
    private readonly arquivoUrl = 'assets/db.json';
    constructor(private http: HttpClient) {}

    // Método para recuperar os dados do arquivo JSON
    getDados(): Observable<any> {
      return this.http.get<any>(this.arquivoUrl);
    }

}
